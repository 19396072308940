<template src="./events.html"></template>

<script>
import Http from '@/shared/http-config'
import moment from 'moment'
import {isMobile} from '@/shared/utils.js'
import introJs from '@/shared/intro.js';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: "Events",
  components: { DatePicker },
  data: function () {
    return {
      introJs : introJs,
      moment : moment,
      events: [],
      seminars: [],
      categories: [],
      locations: [],
      search: {
        event_type: null,
        search: null,
        category: null,
        date: {
          start: null,
          end: null
        },
        location: null,
        sort: "date"
      },
      meta: {
        current_page: 1,
        total: 0,
        per_page: 9
      },
      sortOptions: [
        { value: "date", text: "nach Datum" },
        { value: "range", text: "nach Entferung" },
      ],
    }
  },
  watch: {
    'search.event_type': function(){
      this.getEvents(1);
    },
    $route: function(){
      this.getEvents(1);
    }
  },
  computed: {
    isFiltered () {
      return this.search.search != null || this.search.category != null || this.search.date.start != null || this.search.location != null || this.search.event_type != null || this.search.sort != "date";
    },
    isMobile () {
      return isMobile();
    }
  },
  created: function(){
    let self = this;

    Http.request('GET', '/events/locations')
      .then(function (response) {
        self.locations = response.data;
      });

    Http.request('GET', '/events/categories')
      .then(function (response) {
        self.categories = response.data;
      });

    this.getEvents(1);

    introJs.setOption('steps', [
      {
        element: '[data-intro-step="1"]',
        intro: "In diesem Bereich kannst du alle Events einsehen.",
      },
      {
        element: '[data-intro-step="2"]',
        intro: "Suche hier mit Stichworten nach Events.",
      },
    ]);
    if(self.isMobile) {
      introJs.addSteps([
        {
          element: '[data-intro-step="3"]',
          intro: "Öffne jetzt den Filter und klicke dann auf weiter.",
        },
      ]);
    }
    introJs.addSteps([
      {
        element: '[data-intro-step="4"]',
        intro: "Außerdem kannst du mit diesen Filtern nach Kategorien, Zeiträumen und Orten filtern.",
      },
      {
        element: '[data-intro-step="5"]',
        intro: "Das ist ein Event. Am unteren Abschnitt kannst du sehen, ob das Event bereits gebucht oder besucht wurde oder noch im Eventpool liegt.",
      },
    ]);
    if(self.isMobile) {
      introJs.addSteps([
        {
          element: '[data-intro-step="6"]',
          intro: "Öffne jetzt die Termine und klicke dann auf weiter.",
        },
      ]);
    }
    introJs.addSteps([
      {
        element: '[data-intro-step="7"]',
        intro: "Hier findest du die Daten des Events und die Verfügbarkeit der Plätze. Klicke auf &quot;Jetzt buchen&quot;, um zur Detailseite zu gelangen.",
      },
    ]);
    introJs.addSteps([
      {
        intro: "Vielen dank",
      },
    ]);

    introJs.onbeforechange(function(targetElement) {
      if(!self.isFiltered){
        let seminarCat = self.seminars.find(function(seminar){
          return seminar.booked < 1 && moment(seminar.registration_deadline).isAfter();
        });
        if(seminarCat){
          self.search.category = seminarCat.category.id;
        }
      }
      // dont finish intro on this page (go to details)
      if(introJs._introItems.length -1 === introJs._currentStep){
        return false;
      }
    });

    introJs.firstLoad('events');
  },
  methods: {
    getEvents (page) {
      let self = this;
      self.seminars = [];
      self.events = [];
      let params = '?page=' + page + '&per_page=' + (self.isFiltered ? self.meta.per_page : 100);
      for(let key in self.search) {
        if (key === 'date') {
          if(self.search.date != null && self.search.date.start != null){
            params += '&from=' + moment(self.search.date.start).format('YYYY-MM-DD') + '&to=' + moment(self.search.date.end).format('YYYY-MM-DD')
          }
        }
        else if(self.search[key] != null){
          params += '&' + key + '=' + self.search[key];
        }
      }
      if(self.$route.params.type === 'booked'){
        params += '&booked=1';
      }

      Http.request('GET', '/events' + params)
        .then(function (response) {
          let types = [];
          let seminars = response.data.filter(x => {
            let test = types.indexOf(x.type);
            if (test === -1) {
              types.push(x.type);
            }
            return test === -1;
          });

          let events = {};
          response.data.forEach(x => {
            if (typeof events[x.type] === 'undefined') {
              events[x.type] = [];
            }
            events[x.type].push(x);
          });

          self.seminars = seminars;
          self.events = events;
          self.meta = response.meta;
        });
    },
    resetFilter () {
      this.search = {
          event_type: null,
          search: null,
          category: null,
          date: {
          start: null,
            end: null
        },
        location: null,
        sort: "date"
      }
      this.getEvents(1);
    }
  }
}
</script>

<style scoped>
  .card {
    border-radius: 0;
  }
  .card-img-top {
    max-height: 120px;
    background-color: #eeeeee;
  }
  .event-hint {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    position: absolute;
    right: 0;
    top: 0;
  }
  .event-hint.success {
    border-top: 40px solid var(--success);
  }
  .event-hint.warning {
    border-top: 40px solid var(--warning);
  }
  .event-hint i {
    position: relative;
    top: -37px;
    right: 20px;
    color: #ffffff;
  }
</style>
